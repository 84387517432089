import React, { SVGAttributes } from "react"

const Monogram = (props: SVGAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90.22 63.23" {...props}>
    <polygon points="85.09 19.91 78.62 38.62 72.5 19.91 67.35 19.91 67.35 43.51 70.67 43.51 70.67 24.16 77.23 43.51 80.01 43.51 86.83 24.16 86.83 43.51 90.22 43.51 90.22 19.91 85.09 19.91" />
    <path d="M11.63,32v-1.7H3.06V31.9c0,2.44,1,5,4.31,5s4.26-2.52,4.26-4.88M0,32.22V26.94H14.66v5.12c0,4-1.74,8.1-7.29,8.1-5.15,0-7.37-4-7.37-7.94" />
    <rect x="39.48" width="3.3" height="63.23" />
    <polygon points="18.11 26.9 18.11 3.4 32.2 3.4 32.2 6.65 21.73 6.65 21.73 13.31 31.89 13.31 31.89 16.55 21.73 16.55 21.73 23.65 32.2 23.65 32.2 26.9 18.11 26.9" />
    <path d="M25.23,39.52h-3.5v8.34H25.1c3,0,4.76-1.56,4.76-4.15s-1.69-4.19-4.63-4.19M25.64,51H21.73v8.84H18.11V36.36h7.56c4.28,0,7.9,2.22,7.9,7.35S29.89,51,25.64,51" />
    <polygon points="50.11 43.43 53.72 43.43 53.72 33.08 63.9 33.08 63.9 29.8 53.78 29.8 53.78 23.18 64.22 23.18 64.22 19.9 50.11 19.9 50.11 43.43" />
  </svg>
)

export { Monogram }
