import { useContext } from "react"
import { ArticleContext, ArticleContextInterface } from "../context/article"

const useArticleContext = (
  newContext?: ArticleContextInterface
): [
  ArticleContextInterface,
  React.Dispatch<React.SetStateAction<ArticleContextInterface>> | undefined
] => {
  const articleContext = useContext(ArticleContext),
    setArticleContext = articleContext.setArticleContext

  if (newContext) {
    if (setArticleContext) {
      // Ensure context update retains setter
      const newContextWithSetter = { ...newContext, setArticleContext }
      if (
        newContextWithSetter.uid !== articleContext.uid ||
        newContextWithSetter.length !== articleContext.length
      ) {
        // Update article context
        setArticleContext(newContextWithSetter)
      } else {
        // Provided context does not differ from stored
      }
    } else {
      // setArticleContext unavailable
    }
  }

  return [articleContext, setArticleContext]
}

export default useArticleContext
export { useArticleContext }
