import React, { useRef, useState, FormEvent } from "react"
import MailchimpSubscribe, { EmailFormFields } from "react-mailchimp-subscribe"
import isEmail from "validator/es/lib/isEmail"
import { Trans, useTranslation } from "react-i18next"

import { useEventListener } from "../hooks/useEventListener"
import { usePopper } from "react-popper"

const DataUsage = () => {
  const { t } = useTranslation(),
    [subscriptionDataAnswerActive, setSubscriptionDataAnswerActive] = useState(
      false
    ),
    toggleSubscriptionDataAnswerActive = () =>
      setSubscriptionDataAnswerActive(!subscriptionDataAnswerActive),
    [
      referenceElement,
      setReferenceElement,
    ] = useState<HTMLButtonElement | null>(null),
    [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null),
    [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null),
    { styles, attributes } = usePopper(referenceElement, popperElement, {
      placement: `top-start`,
      modifiers: [
        {
          name: `arrow`,
          options: {
            element: arrowElement,
          },
        },
        {
          name: `offset`,
          options: {
            offset: [0, 8],
          },
        },
      ],
    }),
    closeClickListener = subscriptionDataAnswerActive
      ? (e: Event) => {
          if (
            e.target === popperElement ||
            popperElement?.contains(e.target as Node)
          )
            return
          setSubscriptionDataAnswerActive(false)
        }
      : (e: Event) => {}

  useEventListener("click", closeClickListener)

  return (
    <>
      <button
        type="button"
        ref={setReferenceElement}
        aria-controls="#SubscriptionDataAnswer"
        aria-expanded={subscriptionDataAnswerActive}
        aria-haspopup="true"
        aria-label={`${
          subscriptionDataAnswerActive ? `Hide` : `Show`
        } subscription data usage information`}
        onClick={toggleSubscriptionDataAnswerActive}
      >
        {t(`subscribe.data.question`)}
      </button>

      <div
        className={`${
          subscriptionDataAnswerActive
            ? `opacity-100 pointer-events-auto`
            : `opacity-0 pointer-events-none`
        } transition-opacity duration-200 p-2 w-64 bg-red`}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        id="SubscriptionDataAnswer"
        {...{ inert: subscriptionDataAnswerActive ? undefined : true }}
      >
        <Trans i18nKey="subscribe.data.answer">
          Your email address will be used only to send you marketing newsletters
          and information about Editions de Parfums Frédéric Malle products,
          events and offers. You can unsubscribe at any time by clicking on the
          unsubscribe link in each newsletter. For more information on EDP
          France Holding SAS privacy practices please see our
          <a
            className="underline hover:text-black focus:text-black"
            href="https://www.fredericmalle.com/customer-service-privacy"
          >
            Privacy Policy
          </a>
          .
        </Trans>
        <div
          className="arrow"
          ref={setArrowElement}
          style={Object.assign({}, styles.arrow, {})}
        />
      </div>
    </>
  )
}

const Subscribe = ({ id = `` }: { id: string }) => {
  const { t } = useTranslation(),
    url = `https://fredericmalle.us16.list-manage.com/subscribe/post-json?u=b3d0f6d6d90e8839f1d7e1d93&id=${t(
      `subscribe.list`
    )}`

  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <SubscribeForm
          status={status}
          message={typeof message === `string` ? message : null}
          subscribe={(data: EmailFormFields) => subscribe(data)}
          id={id}
        />
      )}
    />
  )
}

const SubscribeForm = ({
  status,
  message,
  subscribe,
  id,
}: {
  status: "success" | "error" | "sending" | null
  message: string | null
  subscribe: (data: EmailFormFields) => void
  id: string
}) => {
  const form = useRef<HTMLFormElement | null>(null),
    email = useRef<HTMLInputElement | null>(null),
    [invalid, setInvalid] = useState(false)

  const submit = (e: FormEvent) => {
      e.preventDefault()
      e.stopPropagation()

      if (!form.current) return
      if (!email?.current?.value) return

      if (isEmail(email.current.value)) {
        if (invalid) setInvalid(false)

        const data: { [key: string]: any } = {}

        Array.from(form.current.children).forEach((input) => {
          if (input.tagName !== `INPUT`) return
          const name = (input as HTMLInputElement).name,
            value = (input as HTMLInputElement).value
          if (name) data[name] = value
        })

        subscribe(data as EmailFormFields)
      } else {
        if (!invalid) setInvalid(true)
      }
    },
    { t } = useTranslation()

  let details = undefined
  if (message && message.indexOf(`already subscribed`) > -1)
    details = t(`subscribe.redundant`)
  if (message && message.toLowerCase().indexOf(`too many`) > -1)
    details = t(`subscribe.throttled`)

  return (
    <>
      {invalid && <p className="text-sm">{t(`subscribe.invalid`)}</p>}
      {status === `sending` && (
        <p className="text-sm">{t(`subscribe.sending`)}</p>
      )}
      {status === `error` && (
        <p className="text-sm">{details ?? t(`subscribe.error`)}</p>
      )}
      {status === `success` && (
        <p className="text-sm">{t(`subscribe.success`)}</p>
      )}
      {status === null && !invalid && (
        <p className="text-sm">{t(`subscribe.prompt`)}</p>
      )}

      <form
        ref={form}
        className="w-full mt-4 flex flex-wrap sm:flex-no-wrap items-baseline"
      >
        <div className="w-full sm:w-auto">
          <label
            className="sr-only"
            htmlFor={`${id}-${t(`subscribe.first.name`)}`}
          >
            {t(`subscribe.first.name`)}
          </label>
          <input
            className="w-16 mr-2 rounded-none"
            id={`${id}-${t(`subscribe.first.name`)}`}
            name={t(`subscribe.first.name`)}
            placeholder={t(`subscribe.first.title`)}
            type="text"
          />
          <label className="sr-only" htmlFor={`${id}-${t(`subscribe.last`)}`}>
            {t(`subscribe.last`)}
          </label>
          <input
            className="w-16 mr-2 rounded-none"
            id={`${id}-${t(`subscribe.last`)}`}
            name="LAST_NAME"
            placeholder={t(`subscribe.last`)}
            type="text"
          />
        </div>
        <label className="sr-only" htmlFor={`${id}-${t(`subscribe.email`)}`}>
          {t(`subscribe.email`)}
        </label>
        <input
          className="w-32 mt-1 pr-2 box-content sm:w-auto sm:mt-0 sm:pr-0 sm:box-border sm:flex-auto rounded-none"
          id={`${id}-${t(`subscribe.email`)}`}
          name="EMAIL"
          placeholder={t(`subscribe.email`)}
          ref={email}
          required
          type="email"
        />
        <input
          name="LANG_ID"
          type="hidden"
          value={t(`language.id`) as string}
        />
        <input name="OPTIN_DATE" type="hidden" value={new Date().toString()} />
        <input name="ORIG_SRC" type="hidden" value="revue_fm" />
        <button className="px-2" onClick={submit}>
          {t(`subscribe.submit`)}
        </button>
      </form>
      {t(`subscribe.data.answer`).length ? (
        <p className="mt-4 text-sm">
          <DataUsage />
        </p>
      ) : null}
    </>
  )
}

export default Subscribe
export { Subscribe }
