import { useLayoutEffect, useRef, useState } from "react"

// https://usehooks.com/useMedia/
const useMedia = <T extends unknown>(
  queries: string[],
  values: T[],
  defaultValue: T
): T => {
  // Array containing a media query list for each query
  let mediaQueryLists = useRef<MediaQueryList[]>()

  useLayoutEffect(() => {
    mediaQueryLists.current = queries.map((q) => window.matchMedia(q))
  }, [])

  // Function that gets value based on matching media query
  const getValue = () => {
    if (!mediaQueryLists.current) return defaultValue
    // Get index of first media query that matches
    const index = mediaQueryLists.current.findIndex((mql) => mql.matches)
    // Return related value or defaultValue if none
    return typeof values[index] !== "undefined" ? values[index] : defaultValue
  }

  // State and setter for matched value
  const [value, setValue] = useState(getValue)

  useLayoutEffect(
    () => {
      // Event listener callback
      // Note: By defining getValue outside of useEffect we ensure that it has ...
      // ... current values of hook args (as this hook callback is created once on mount).
      const handler = () => setValue(getValue)
      // Set a listener for each media query with above handler as callback.
      mediaQueryLists.current &&
        mediaQueryLists.current.forEach((mql) => {
          mql.addListener(handler)
          mql.matches && handler()
        })
      // Remove listeners on cleanup
      return () =>
        mediaQueryLists.current &&
        mediaQueryLists.current.forEach((mql) => mql.removeListener(handler))
    },
    [] // Empty array ensures effect is only run on mount and unmount
  )

  return value
}

export default useMedia
export { useMedia }
