import { useContext } from "react"
import { PreviewContext } from "../context/preview"

const firstKey = (object: { [key: string]: any }): string => {
  return object && Object.keys(object)[0]
}

const objectKey = (object: { [key: string]: any }, key?: string) => {
  const first = firstKey(object)
  return first && key && object[first][key]
}

const usePreview = () => {
  return useContext(PreviewContext)
}

const usePreviewData = <T extends unknown>(
  staticData: T & { [key: string]: any }
): T => {
  const { exists, mergePrismicPreviewData, previewData } = usePreview()

  // Return early if no preview exists
  if (!exists) return staticData

  const staticKey = firstKey(staticData),
    previewKey = firstKey(previewData),
    staticPid = objectKey(staticData, `prismicId`),
    previewPid = objectKey(previewData, `prismicId`),
    prependedData = Object.assign({}, staticData),
    mergedData = mergePrismicPreviewData({ staticData, previewData })

  // Prepend preview in certain cases unhandled by gatsby-source-prismic:
  // E.g., previewData is for `prismicProject` and staticData is for `allPrismicProject`.
  if (
    staticKey &&
    previewKey &&
    staticKey ===
      `all${previewKey.substr(0, 1).toUpperCase()}${previewKey.substr(1)}`
  ) {
    const edgeIndex = prependedData[staticKey].edges.findIndex(
      (edge: any) => edge.node.prismicId === previewPid
    )

    if (edgeIndex === -1)
      prependedData[staticKey].edges.unshift({ node: previewData[previewKey] })
    else
      prependedData[staticKey].edges.splice(edgeIndex, 1, {
        node: previewData[previewKey],
      })

    return prependedData
  }

  // mergePrismicPreviewData will merge objects with different ids if they are of the same type.
  // We want to prevent this from occurring since it leads to all content being overwritten during preview
  return !staticPid || staticPid === previewPid ? mergedData : staticData
}

export default usePreviewData
export { usePreview, usePreviewData }
