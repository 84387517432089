import { createContext } from "react"
import { PrismicImageInterface } from "../../graphql-types"

export interface ArticleContextInterface {
  uid?: string
  index?: number
  title?: string
  date?: number
  languages?: { lang: string; uid: string }[]
  length?: number
  nextIndex?: number
  nextPathname?: string
  nextTitle?: string
  nextDate?: number
  nextImage?: PrismicImageInterface
  previousIndex?: number
  previousPathname?: string
  previousTitle?: string
  previousDate?: string
  previousImage?: PrismicImageInterface
  setArticleContext?: React.Dispatch<
    React.SetStateAction<ArticleContextInterface>
  >
}

const ArticleContext = createContext<ArticleContextInterface>({
  uid: undefined,
  index: undefined,
  title: undefined,
  date: undefined,
  languages: undefined,
  length: undefined,
  nextIndex: undefined,
  nextPathname: undefined,
  nextTitle: undefined,
  nextDate: undefined,
  nextImage: undefined,
  previousIndex: undefined,
  previousPathname: undefined,
  previousTitle: undefined,
  previousDate: undefined,
  previousImage: undefined,
  setArticleContext: undefined,
})

export { ArticleContext }
