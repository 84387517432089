import React from "react"
import { useJSONCookie } from "../hooks/useCookie"
import { CookieAttributes } from "js-cookie"
import { usePortal } from "../hooks/usePortal"

type ReturnsJSXElement = (
  close: (override?: CookieAttributes) => void
) => JSX.Element | JSX.Element[]

const Sidebar = ({
  children,
  options = {
    path: `/`,
    expires: 0,
  },
  id,
  x = true,
}: {
  id: string
  children: JSX.Element | JSX.Element[] | ReturnsJSXElement
  options?: CookieAttributes
  x: boolean
}) => {
  const [open, setOpen] = useJSONCookie(id, true, options),
    close = (override?: CookieAttributes, callback?: () => void) => {
      if (open) {
        setOpen(false, !override?.type && override)
        if (callback) callback()
      }
    },
    sidebar = (
      <div
        {...{
          className: `sidebar-container pointer-events-auto m-4 ml-0 mt-0 w-full sm:w-3/4 md:w-1/2 xl:w-1/4 ${
            open ? `open` : `closed`
          }`,
          ariaHidden: !open,
          inert: !open,
        }}
      >
        <div
          className={`sidebar relative p-8 ${
            x ? `pr-12` : ``
          } bg-gray-900 text-white`}
        >
          {x && (
            <button
              className="absolute top-0 right-0 leading-0"
              style={{ fontSize: `1.35rem`, width: `34px`, height: `34px` }}
              onClick={close}
            >
              &times;
            </button>
          )}
          {typeof children === `function` ? children(close) : children}
        </div>
      </div>
    )

  return (
    usePortal(sidebar, {
      className: `fixed pointer-events-none inset-0 flex flex-col items-start justify-start pt-36`,
    }) || null
  )
}

export default Sidebar
export { Sidebar }
