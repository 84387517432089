import React, { useCallback, useRef, useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import { useMedia } from "../hooks/useMedia"
import { Monogram } from "./logos"
import { Progress } from "./progress"
import { SiblingLink } from "./siblingLink"
import { useEventListener } from "../hooks/useEventListener"
import { Button, Dropdown, Menu } from "./dropdown"
import { Link, navigate } from "gatsby"
import { Credits } from "./credits"
import { Subscribe } from "./subscribe"
import { useTranslation } from "react-i18next"
import { useArticleContext } from "../hooks/useArticleContext"
import "wicg-inert"

const Navigation = () => {
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false),
    toggleMobileNavigationActive = () =>
      setMobileNavigationActive(!mobileNavigationActive),
    closeMobileNavigation = () => {
      mobileNavigationActive && setMobileNavigationActive(false)
    },
    viewport = useMedia(
      [
        `(min-width: 74rem)`,
        `(min-width: 66rem)`,
        `(min-width: 58rem)`,
        `(min-width: 33rem)`,
      ],
      [4, 3, 2, 1],
      0
    ),
    { pathname } = useLocation(),
    [pinned, setPinned] = useState(true),
    scrollTop = useRef(
      typeof document !== `undefined`
        ? document?.scrollingElement?.scrollTop ?? 0
        : 0
    ),
    lastPinned = useRef(
      typeof document !== `undefined`
        ? document?.scrollingElement?.scrollTop ?? 0
        : 0
    ),
    scrollHandler = useCallback(
      (e: Event) => {
        if (viewport <= 1) return

        const newScrollTop =
            (e?.target as Document)?.scrollingElement?.scrollTop ??
            scrollTop.current,
          scrollChange = newScrollTop - scrollTop.current

        // Update last pin reference whenever scollChange is < 0
        if (scrollChange < 0) lastPinned.current = newScrollTop

        // If newScrollTop is > 100 from last pin reference, unpin the navigation
        if (newScrollTop - lastPinned.current > 100 && pinned) setPinned(false)

        // If scrollChange is < -10, pin the navigation
        if (scrollChange < 0 && !pinned) setPinned(true)

        // Store newScrollTop
        scrollTop.current = newScrollTop ?? scrollTop.current
      },
      [pinned, viewport]
    ),
    // Redirect fredericmalle.tld urls to the relevant
    // locale domain based on current language.
    clickHandler = (e: MouseEvent) => {
      const path = e.composedPath && e.composedPath(),
        target = path
          ? (path.find((node) => (node as Element).tagName === `A`) as Element)
          : (e.target as Element),
        href = target?.getAttribute(`href`)

      // Not a link, ignore click
      if (target?.tagName !== `A` || !href) return

      // Is internal link, ignore click, handle normally
      if (
        href.indexOf(window?.location?.host) > -1 ||
        [`/`, `#`].indexOf(href.substring(0, 1)) > -1
      )
        return

      // Look for a brand domain in href
      const brandDomains = [
          `fredericmalle.com`,
          `fredericmalle.co.uk`,
          `fredericmalle.eu`,
          `fredericmalle.fr`,
        ],
        localeSubdirectories = [
          null,
          null,
          t(`language.name`) === `French` ? `/fr-e-UF` : `/en-e-UF`,
          null,
        ],
        localeQueries = [
          `LOCALE=en_US&online=1`,
          null,
          `LOCALE=en_UF&online=1`,
          null,
        ],
        hrefBrandDomainIndex = brandDomains.findIndex(
          (url) => href.indexOf(url) > -1
        ),
        brandDomain = brandDomains[hrefBrandDomainIndex],
        localeBrandDomain = t(`brand.url`).substring(4),
        localeBrandDomainIndex = brandDomains.indexOf(localeBrandDomain),
        localeSubdirectory = localeSubdirectories[localeBrandDomainIndex],
        localeQuery = localeQueries[localeBrandDomainIndex]

      // Replace found brand domain with the appropriate locale url
      let newHref = href

      // If brandDomain was found, update query string to ensure correct locale
      if (brandDomain) {
        newHref = href.replace(brandDomain, localeBrandDomain)

        if (
          localeSubdirectory &&
          newHref.indexOf(`${localeBrandDomain}${localeSubdirectory}`) === -1
        )
          newHref = newHref.replace(
            localeBrandDomain,
            `${localeBrandDomain}${localeSubdirectory}`
          )

        if (localeQuery)
          newHref +=
            newHref.indexOf(`?`) > -1 ? `&${localeQuery}` : `?${localeQuery}`
      }

      e.preventDefault()
      window.open(newHref, ``, `noopener=true`)
    },
    { t, i18n } = useTranslation(),
    [{ uid, languages, length }] = useArticleContext(),
    articlesLength = length ? `${length}`.padStart(3, `0`) : `000`

  useEffect(() => {
    const handleLanguageChange = (newLang: string) => {
      const newLangAlternate =
        Array.isArray(languages) &&
        languages.length > 0 &&
        languages.find(({ lang }) => newLang === lang)

      let newUrl = newLangAlternate
        ? uid
          ? pathname
              .replace(pathname.substring(0, 6), `/${newLang}`)
              .replace(uid, newLangAlternate.uid)
          : pathname.replace(pathname.substring(0, 6), `/${newLang}`)
        : `/${newLang}`

      navigate(newUrl)
    }

    i18n.on(`languageChanged`, handleLanguageChange)

    return () => i18n.off(`languageChanged`, handleLanguageChange)
  }, [uid, languages, i18n, pathname])

  useEventListener(`scroll`, scrollHandler)
  useEventListener(`click`, clickHandler)

  const mobileNavigationProps = {
    id: `MobileNavigation`,
    role: `navigation`,
    className: `md:hidden fixed h-full overflow-auto transform transition-transform duration-300 ${
      mobileNavigationActive ? `translate-y-0` : `-translate-y-full`
    } w-full bg-gray-900 text-white text-sm tracking-wide z-10`,
    style: { paddingTop: `var(--nav-height)` },
    inert: mobileNavigationActive ? undefined : true,
  }

  const locales = [
      {
        display: `EU`,
        region: `eu`,
        language: `en`,
      },
      {
        display: `FR`,
        region: `fr`,
        language: `fr`,
      },
      {
        display: `UK`,
        region: `gb`,
        language: `en`,
      },
      {
        display: `US`,
        region: `us`,
        language: `en`,
      },
    ],
    localeButtons = locales
      .filter(
        (locale) => `${locale.language}-${locale.region}` !== i18n.language
      )
      .map((locale) => (
        <button
          className="px-3 md:px-6"
          onClick={() =>
            i18n.changeLanguage(`${locale.language}-${locale.region}`)
          }
        >
          {locale.display}
        </button>
      ))

  const mobileNavigation = (
      <nav {...mobileNavigationProps}>
        <div className="w-full min-h-full inline-flex flex-col items-start">
          {/* Monogram */}
          <div>
            <h1>
              <Link to={`/${i18n.language}`} onClick={closeMobileNavigation}>
                <span className="sr-only">{t(`navigation.index`)}</span>
                <Monogram
                  className="w-20 p-6 box-content"
                  style={{ fill: `white` }}
                />
              </Link>
            </h1>
          </div>
          {/* Editions de Parfums Frédéric Malle */}
          <div className="w-full -max-w-6 mx-6 my-3">
            <p className="text-base tracking-normal font-medium mb-1">
              {t(`brand.full`)}
            </p>
            <div>
              <a className="block" href="https://www.fredericmalle.com/">
                {t(`navigation.home`)}
              </a>
              <a
                className="block"
                href="https://www.fredericmalle.com/about/frederic-malle"
              >
                {t(`links.about`)}
              </a>
              <a className="block" href={t(`links.stores.url`)}>
                {t(`links.stores.title`)}
              </a>
            </div>
          </div>
          {/* Localization */}
          <div className="w-full -max-w-6 mx-6 my-3">
            <p className="text-base tracking-normal font-medium mb-1">
              {t(`locale.country`)}
            </p>
            <div>
              <button
                className={i18n.language === `en-eu` ? `border-b` : ``}
                onClick={() => i18n.changeLanguage(`en-eu`)}
              >
                EU
              </button>
              {` `}
              <button
                className={i18n.language === `fr-fr` ? `border-b` : ``}
                onClick={() => i18n.changeLanguage(`fr-fr`)}
              >
                FR
              </button>
              {` `}
              <button
                className={i18n.language === `en-gb` ? `border-b` : ``}
                onClick={() => i18n.changeLanguage(`en-gb`)}
              >
                UK
              </button>
              {` `}
              <button
                className={i18n.language === `en-us` ? `border-b` : ``}
                onClick={() => i18n.changeLanguage(`en-us`)}
              >
                US
              </button>
            </div>
          </div>
          {/* Shop */}
          <div className="w-full -max-w-6 mx-6 my-3">
            <p className="text-base tracking-normal font-medium mb-1">
              {t(`links.shop`)}
            </p>
            <div>
              <a
                className="block"
                href="https://www.fredericmalle.com/products/19566/perfume"
              >
                {t(`links.perfumes`)}
              </a>
              <a
                className="block"
                href="https://www.fredericmalle.com/body-collection"
              >
                {t(`links.body`)}
              </a>
              <a
                className="block"
                href="https://www.fredericmalle.com/home-gifts"
              >
                {t(`links.home`)}
              </a>
            </div>
          </div>
          {/* Social */}
          <div className="w-full -max-w-6 mx-6 my-3">
            <p className="text-base tracking-normal font-medium mb-1">Social</p>
            <div>
              <a
                className="block"
                href="https://www.instagram.com/fredericmalle/"
              >
                Instagram
              </a>
              <a
                className="block"
                href="https://www.facebook.com/FredericMalle/"
              >
                Facebook
              </a>
              <a className="block" href="https://www.youtube.com/fredericmalle">
                YouTube
              </a>
            </div>
          </div>
          {/* Information */}
          <div className="w-full -max-w-6 mx-6 my-3">
            <p className="text-base tracking-normal font-medium mb-1">
              {t(`links.information`)}
            </p>
            <div>
              <a className="block" href="mailto:revue@fredericmalle.com">
                {t(`links.contact`)}
              </a>
              <a
                className="block"
                href="https://www.fredericmalle.com/customer-service-terms"
              >
                {t(`links.terms`)}
              </a>
              <a
                className="block"
                href="https://www.fredericmalle.com/customer-service-privacy"
              >
                {t(`links.privacy`)}
              </a>
              <a
                className="block"
                href="https://www.fredericmalle.com/accessibility"
              >
                {t(`links.accessibility`)}
              </a>
              <Credits />
            </div>
          </div>
          {/* Subscribe */}
          <div className="w-full -max-w-6 mx-6 my-3 mb-6">
            <p className="text-base tracking-normal font-medium mb-1">
              {t(`subscribe.title`)}
            </p>
            <div className="w-full">
              <Subscribe id="navigation" />
            </div>
          </div>
        </div>
      </nav>
    ),
    secondaryNavigation = (
      <nav
        className={`transform transition duration-500 ${
          pinned || viewport <= 1 ? `` : `-translate-y-full `
        } sticky z-20 md:z-10 top-0 w-full inline-flex items-center justify-between bg-white md:bg-transparent font-medium uppercase`}
      >
        <div className="hidden md:flex">
          {/* Locale select */}
          <Dropdown id="localeSelect" state={pinned ? undefined : false}>
            <Button>
              <div className="p-3 md:p-6 w-20">
                {
                  locales.find(
                    (loc) => `${loc.language}-${loc.region}` === i18n.language
                  )?.display
                }
              </div>
            </Button>
            <Menu className="-mt-3 md:-mt-6 w-20 font-normal">
              {localeButtons}
            </Menu>
          </Dropdown>
          {/* Out to EDPFM */}
          <Dropdown id="editionsDeParfums" state={pinned ? undefined : false}>
            <Button element={<a href={`https://${t(`brand.url`)}`} />}>
              <span className="p-3 md:p-6">{t(`brand.short`)}</span>
            </Button>
            <Menu className="-mt-3 md:-mt-6 font-normal">
              <a className="px-3 md:px-6" href={`https://${t(`brand.url`)}`}>
                {t(`brand.vanity_url`)}
              </a>
            </Menu>
          </Dropdown>
        </div>
        {/* Primary Logo */}
        <h1
          className="static md:absolute md:left-1/2 md:transform md:-translate-x-1/2 text-base font-medium uppercase"
          onClick={closeMobileNavigation}
        >
          <Link className="p-3 md:p-6" to={`/${i18n.language}`}>
            <span className="md:text-red">{t(`site.revue`)}</span>{" "}
            {t(`site.name.uppercase`)}
          </Link>
        </h1>
        {/* Sibling article links */}
        <div className="relative hidden md:block">
          {pathname.indexOf(`/articles/`) > -1 ? (
            <>
              <SiblingLink direction="next" />
              {` / `}
              <SiblingLink direction="previous" />
            </>
          ) : (
            <span className="font-medium pr-3 md:pr-6">
              #{t(`categories.default`)} / {articlesLength}
            </span>
          )}
        </div>
        <button
          aria-controls="#MobileNavigation"
          aria-expanded={mobileNavigationActive}
          aria-haspopup="true"
          aria-label={`${mobileNavigationActive ? `Close` : `Open`} menu`}
          className="block md:hidden p-3 md:p-6 font-medium"
          onClick={toggleMobileNavigationActive}
        >
          <svg
            className={`w-5 ${mobileNavigationActive ? `hidden` : `block`}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
          <svg
            className={`w-5 ${mobileNavigationActive ? `block` : `hidden`}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </nav>
    )

  return (
    <>
      <style type="text/css">{`
        :root {
          --nav-height: 4.25rem;
          --mobile-nav-height: 2.75rem;
        }
      `}</style>
      <Progress
        className="fixed z-20 top-0 -mt-2px md:mt-0 transform translate-y-full md:translate-y-0 h-2px md:h-1"
        key={pathname}
        pathname={pathname}
        style={
          viewport <= 1
            ? {
                top: `var(--mobile-nav-height)`,
              }
            : {}
        }
      />
      {secondaryNavigation}
      {mobileNavigation}
    </>
  )
}

export default Navigation
export { Navigation }
