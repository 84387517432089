import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import { usePreviewData } from "../hooks/usePreview"
import Img from "gatsby-image"
import { align, justify } from "../utilities"
import { getI18n } from "react-i18next"
import { Query } from "../../graphql-types"

const Splash = ({ dismiss }: { dismiss: () => void }) => {
  const staticData = useStaticQuery<Query>(SplashQuery),
    { allPrismicSplash } = usePreviewData(staticData),
    { pathname } = useLocation(),
    isPreview = pathname.indexOf(`/preview`) === 0,
    { language: i18nLanguage } = getI18n(),
    language = i18nLanguage.split(`-`)[0],
    prismicSplash =
      allPrismicSplash.edges.find(
        ({ node }) => node.lang.indexOf(language) === 0
      )?.node ?? allPrismicSplash.edges.find(({ node }) => node.data)?.node,
    splashHasImage = prismicSplash?.data?.image?.fluid,
    [splashLoaded, setSplashLoaded] = useState(false),
    [opacity, setOpacity] = useState(1),
    fadeDelay = 2250,
    fadeDuration = 500

  let splashImageFluid = splashHasImage
  if (splashImageFluid) splashImageFluid.sizes = `100vw`

  useEffect(() => {
    if (!isPreview && !splashHasImage) {
      setTimeout(() => {
        setSplashLoaded(true)
        setOpacity(0)
      }, 250)
    }
  }, [isPreview, splashHasImage])

  useEffect(() => {
    if (!isPreview && splashLoaded)
      setTimeout(dismiss, fadeDuration + fadeDelay)
  }, [isPreview, dismiss, splashLoaded])

  return (
    <div
      className={`splash fixed w-full min-h-screen flex items-center justify-center z-30 transition-opacity`}
      style={{
        cursor: `pointer`,
        minHeight: `-webkit-fill-available`,
        backgroundColor: prismicSplash?.data?.background_color ?? `white`,
        opacity: opacity,
        transitionDelay: `${fadeDelay}ms`,
        transitionDuration: `${fadeDuration}ms`,
      }}
      onClick={dismiss}
      key={pathname}
    >
      <Helmet>
        <body className="overflow-hidden" />
      </Helmet>
      <div
        className={`absolute w-full h-full flex flex-col p-3 md:p-6 ${align(
          prismicSplash?.data?.align
        )} ${justify(prismicSplash?.data?.justify)}`}
      >
        {splashImageFluid && (
          <Img
            alt={prismicSplash?.data?.image?.alt ?? ``}
            className="absolute inset-0 w-full h-full"
            fluid={splashImageFluid}
            style={{ position: `absolute` }}
            onLoad={() => {
              setTimeout(() => {
                setSplashLoaded(true)
                setOpacity(0)
              }, 250)
            }}
          />
        )}
        <div
          className={`z-10 rte ${align(prismicSplash?.data?.align, true)}`}
          dangerouslySetInnerHTML={{
            __html: prismicSplash?.data?.text?.html ?? ``,
          }}
          style={{
            color: prismicSplash?.data?.text_color ?? `inherit`,
          }}
        />
      </div>
    </div>
  )
}

export default Splash
export { Splash }

export const SplashQuery = graphql`
  query Splash {
    allPrismicSplash {
      edges {
        node {
          data {
            align
            background_color
            image {
              ... on PrismicImageType {
                ...PrismicImageType
              }
            }
            justify
            text {
              html
            }
            text_color
          }
          id
          lang
          prismicId
        }
      }
    }
  }
`
