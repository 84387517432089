/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import { Wrapper } from "./src/components/wrapper"

export const wrapPageElement = (params) => {
  return <Wrapper {...params} />
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation !== null) {
    const skipLink = document.querySelector("#reach-skip-nav")
    if (skipLink) {
      skipLink.focus()
    }
  }
}

export const onClientEntry = () => {
  console.log(`Website by Spevack Loeb. https://studio.sl.`)
}
