import { createContext } from "react"

export interface PreviewContextInterface {
  isPreview?: boolean | null
  isLoading?: boolean | null
  exists: boolean
  mergePrismicPreviewData: (args: any) => any
  previewData?: any
  path?: string | null
  setContext?: React.Dispatch<React.SetStateAction<PreviewContextInterface>>
}

const PreviewContext = createContext<PreviewContextInterface>({
  exists: false,
  mergePrismicPreviewData: () => {},
})

export { PreviewContext }
