import React, { useState, useContext, useEffect } from "react"
import {
  navigate,
  WrapPageElementNodeArgs,
  WrapPageElementBrowserArgs,
} from "gatsby"
import { PreviewContext } from "../context/preview"
import { ArticleContext } from "../context/article"
import { SkipNavLink } from "@reach/skip-nav"
import { Layout } from "./layout"
import { i18n } from "./i18n"
import { I18nextProvider } from "react-i18next"
import { usePlainCookie } from "../hooks/useCookie"
import { ArticleContextInterface } from "../context/article"

import eneu from "../locales/en-eu/translation.json"
import engb from "../locales/en-gb/translation.json"
import enus from "../locales/en-us/translation.json"
import frfr from "../locales/fr-fr/translation.json"
import { useEventListener } from "../hooks/useEventListener"

declare global {
  interface Window {
    __edpfm?: {
      ignoreChangeLanguage?: boolean
    }
  }
}

const Wrapper = (
  params: WrapPageElementNodeArgs | WrapPageElementBrowserArgs
) => <I18nWrapper {...params} />

const I18nWrapper = (
  params: WrapPageElementNodeArgs | WrapPageElementBrowserArgs
) => {
  /***** Language selection *****
   * 1. Except for the root, urls contain language and locale information: /en-gb/*.
   *
   * 2. Unless a query variable is set by EDPFM (indicating that no redirects should occur)
   *
   *    a user's country (region) is deduced via IP by Netlify. A list of redirects built in
   *     gatsby-node is provided for alternate languages of every article url,
   *    and updated whenever the site is built. E.g.:
   *
   *      # This first rule represents the override
   *      # /en-eu/an-article-translate?noredirect=true will match before any redirects
   *
   *      /* noredirect=value /:splat 200
   *      /en-eu/an-article-to-translate /en-us/an-article-to-translate 303! Country=us
   *      /en-eu/an-article-to-translate /en-gb/an-article-to-translate 303! Country=uk
   *      /en-eu/an-article-to-translate /fr-fr/un-article-a-traduire 303! Country=fr
   *      /en-eu/an-article-to-translate /en-eu/an-article-to-translate 200
   *      /en-gb/an-article-to-translate /en-us/an-article-to-translate 303! Country=us
   *      /en-gb/an-article-to-translate /en-gb/an-article-to-translate 200 Country=uk
   *      /en-gb/an-article-to-translate /fr-fr/un-article-a-traduire 303! Country=fr
   *      /en-gb/an-article-to-translate /en-eu/an-article-to-translate 303!
   *      /en-us/an-article-to-translate /en-us/an-article-to-translate 200 Country=us
   *      /en-us/an-article-to-translate /en-gb/an-article-to-translate 303! Country=uk
   *      /en-us/an-article-to-translate /fr-fr/un-article-a-traduire 303! Country=fr
   *      /en-us/an-article-to-translate /en-eu/an-article-to-translate 303!
   *      /fr-fr/un-article-a-traduire /en-us/an-article-to-translate 303! Country=us
   *      /fr-fr/un-article-a-traduire /en-gb/an-article-to-translate 303! Country=uk
   *      /fr-fr/un-article-a-traduire /fr-fr/un-article-a-traduire 200 Country=fr
   *      /fr-fr/un-article-a-traduire /en-eu/an-article-to-translate 303!
   *
   * 3. The user has manually chosen a language—their selection is stored and reinstated.
   *
   *    The above technique incorrectly redirects users whose preferred language differs
   *    from their physical location. An additional redirect is required to correct this mistake.
   *
   *    We know this mistake has occurred when the following are true:
   *      hasNoRedirect is false
   *      initialLang differs from savedLang
   *
   *    In such a case, we should identify the appropriate url for the requested content in savedLang
   *    and redirect.
   */
  const props = params.props,
    location = props.location,
    pageContext = props.pageContext as ArticleContextInterface,
    supportedLangs = [`en-eu`, `en-gb`, `en-us`, `fr-fr`],
    defaultLang = supportedLangs[0],
    [initialUrl] = useState(location.pathname),
    [initialLang] = useState(initialUrl.substring(1, 6)),
    [initialUid] = useState(pageContext?.uid),
    [savedLang, setSavedLang] = usePlainCookie(`language`, undefined, {
      expires: 365,
    }),
    // This cookie overrides netlify's redirects, obviating case 3 above.
    [savedCountry, setSavedCountry] = usePlainCookie(`nf_country`, undefined, {
      expires: 365,
    }),
    hasNoRedirect = location.search.indexOf(`noredirect=`) > -1,
    isPreview = initialUrl.indexOf(`/preview`) === 0,
    bestKnownLang = hasNoRedirect
      ? supportedLangs.indexOf(initialLang) > -1
        ? initialLang
        : defaultLang
      : supportedLangs.indexOf(savedLang) > -1
      ? savedLang
      : supportedLangs.indexOf(initialLang) > -1
      ? initialLang
      : defaultLang

  const savedLangMismatch = savedLang && savedLang !== initialLang,
    savedLangUid =
      pageContext?.languages &&
      pageContext.languages.find(
        (languageObject) => languageObject.lang === savedLang
      )?.uid,
    correctUrl =
      typeof initialUid !== `undefined`
        ? typeof savedLangUid !== `undefined`
          ? initialUrl
              .replace(initialLang, savedLang)
              .replace(initialUid, savedLangUid)
          : // No uid for alternate language, go to the index
            `/${savedLang}`
        : initialUrl.replace(initialLang, savedLang)

  useEffect(() => {
    if (
      savedLangMismatch &&
      !hasNoRedirect &&
      !isPreview &&
      correctUrl &&
      location.pathname !== correctUrl
    ) {
      console.log(
        `Saved language mismatch. Redirecting to corrected url: ${correctUrl}.`
      )
      navigate(correctUrl)
    }
  }, [])

  // Initialize i18n
  if (!i18n.isInitialized) {
    i18n.init({
      resources: {
        "en-eu": eneu,
        "en-gb": engb,
        "en-us": enus,
        "fr-fr": frfr,
      },
      lng: bestKnownLang,
      fallbackLng: `en-eu`,
      supportedLngs: [`en-eu`, `en-gb`, `en-us`, `fr-fr`],
      lowerCaseLng: true,

      interpolation: {
        escapeValue: false,
      },
    })
  }

  // Change language for every page during SSR so correct translations are rendered.
  if (typeof window === `undefined` && i18n.language !== bestKnownLang) {
    i18n.changeLanguage(bestKnownLang)
  }

  // Store user selection when language changes so we may override provided url
  useEffect(() => {
    i18n.on(`languageChanged`, (newLang: string) => {
      // Escape-hatch to allow previewing in document language without overriding user selection
      if (window?.__edpfm?.ignoreChangeLanguage) {
        window.__edpfm.ignoreChangeLanguage = false
      } else {
        setSavedLang(newLang)
        setSavedCountry(newLang.split(`-`)[1])
      }
    })
    return () =>
      i18n.off(`languageChanged`, (newLang: string) => {
        setSavedLang(newLang)
        setSavedCountry(newLang.split(`-`)[1])
      })
  }, [])

  return (
    <I18nextProvider i18n={i18n}>
      <PreviewWrapper {...params} />
    </I18nextProvider>
  )
}

const PreviewWrapper = (
  params: WrapPageElementNodeArgs | WrapPageElementBrowserArgs
) => {
  const [previewContext, setPreviewContext] = useState(
      useContext(PreviewContext)
    ),
    [articleContext, setArticleContext] = useState(useContext(ArticleContext)),
    [initialUrl] = useState(params.props.location.href),
    keyUpHandler = (e: KeyboardEvent) => {
      if (!previewContext.isPreview) return

      switch (e.key) {
        case `p`:
          navigator.clipboard.writeText(initialUrl)
          break
        default:
          break
      }
    }

  useEventListener(`keyup`, keyUpHandler)

  const initialContext =
    typeof articleContext.setArticleContext === `undefined`
      ? params.props.pageContext
      : articleContext

  // Supply preview context for all
  return (
    <PreviewContext.Provider
      value={{ ...previewContext, setContext: setPreviewContext }}
    >
      <ArticleContext.Provider value={{ ...initialContext, setArticleContext }}>
        <SkipNavLink />
        <Layout {...params} />
      </ArticleContext.Provider>
    </PreviewContext.Provider>
  )
}

export default Wrapper
export { Wrapper }
