import React, { useEffect } from "react"
import { Sidebar } from "./sidebar"
import { useJSONCookie } from "../hooks/useCookie"
import { useTranslation } from "react-i18next"
import Cookies from "js-cookie"

declare global {
  interface Window {
    "ga-disable-UA-176862452-1"?: boolean
    ga: any
  }
}

const sendPageview = () => {
  window["ga-disable-UA-176862452-1"] = false
  if (typeof window.ga === `function`)
    window.ga.getByName(`t0`).send(`pageview`)
}

const GDPR = () => {
  const [gdpr] = useJSONCookie(`gdpr`, true),
    { t } = useTranslation()

  // When GDPR cookie changes
  useEffect(() => {
    window["ga-disable-UA-176862452-1"] = gdpr

    // If the banner has been dismissed, do nothing
    if (!gdpr) return

    // If the banner is not dismissed, clear any existing cookies
    const cookies = Cookies.get(),
      cookieNames = Object.keys(cookies)
    cookieNames.forEach((name) => Cookies.remove(name))
  }, [gdpr])

  return (
    <Sidebar id="gdpr" x={false}>
      {(close) => (
        <>
          <p className="font-medium mb-2">{t(`cookie.title`)}</p>
          <p className="text-sm">{t(`cookie.content`)}</p>
          <button
            className="mt-2 py-1 px-2 border border-white font-medium text-sm"
            onClick={() => {
              close({ expires: 365 })
              sendPageview()
            }}
          >
            {t(`cookie.accept`)}
          </button>
          <button
            className="mt-2 ml-1 py-1 px-2 border border-transparent text-sm"
            onClick={close}
          >
            {t(`cookie.decline`)}
          </button>
        </>
      )}
    </Sidebar>
  )
}

export default GDPR
export { GDPR }
