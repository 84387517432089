import React, { useState } from "react"
import { WrapPageElementNodeArgs, WrapPageElementBrowserArgs } from "gatsby"
import { SEO } from "./seo"
import { fonts, Fontface } from "../fonts"
import { Splash } from "./splash"
import { Navigation } from "./navigation"
import { SkipNavContent } from "@reach/skip-nav"
import { GDPR } from "./gdpr"
import { Footer } from "./footer"
import "focus-visible"
import "../css/index.css"
import { Helmet } from "react-helmet"

const Layout = ({
  element,
  ...rest
}: WrapPageElementNodeArgs | WrapPageElementBrowserArgs) => {
  const [splashDismissed, setSplashDismissed] = useState(false),
    dismissSplash = () => setSplashDismissed(true)

  return (
    <div className="min-h-screen flex flex-col">
      <SEO />
      <Helmet>
        {/* Starling */}
        <link
          href="https://cloud.typenetwork.com/projects/4260/fontface.css/"
          rel="stylesheet"
          type="text/css"
        />
        {/* Akzidenz */}
        <link
          href="https://use.typekit.net/dwm0lou.css"
          rel="stylesheet"
          type="text/css"
        />
      </Helmet>
      {/* Times */}
      <Fontface
        display="swap"
        fonts={{
          TimesLtPro: fonts.TimesLtPro,
        }}
      />
      {!splashDismissed && <Splash dismiss={dismissSplash} />}
      <Navigation />
      <SkipNavContent />
      <GDPR />
      <div className="flex-grow">{element}</div>
      <Footer />
    </div>
  )
}

export default Layout
export { Layout }
