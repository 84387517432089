import React, {
  useEffect,
  useRef,
  useState,
  ReactElement,
  cloneElement,
  useCallback,
  HTMLAttributes,
} from "react"
import { useEventListener } from "../hooks/useEventListener"
import merge from "deepmerge"
import "wicg-inert"

const containsActiveElement = (element: HTMLElement) => {
  if (element.contains(document.activeElement)) return true
  return false
}

const Dropdown = ({
  children,
  id,
  state,
  ...props
}: { children: ReactElement[]; id: string; state?: boolean } & HTMLAttributes<
  HTMLDivElement
>) => {
  const dropdownRef = useRef<HTMLDivElement>(null),
    dropdown =
      typeof document !== `undefined` &&
      document.querySelector(`#${id}-container`),
    // Open can be caused by either focus or hover
    // Separating these causes allows us to remain open
    // when the hovered component loses focus,
    // or when the focused component loses hover
    [focus, setFocus] = useState(false),
    [focusOpen, setFocusOpen] = useState(false),
    [hover, setHover] = useState(false),
    [hoverOpen, setHoverOpen] = useState(false),
    // Provided state overrides hover/focus status
    open = focusOpen || hoverOpen,
    button = children[0],
    menu = children[1],
    // Observe keyup events
    keyUpHandler = useCallback(
      (e: KeyboardEvent) => {
        switch (e.key) {
          // Enter or Spacebar toggle focusOpen
          case `Enter`:
          case ` `:
            focus && setFocusOpen(!focusOpen)
            break
          // Escape sets focusOpen false
          case `Escape`:
            focusOpen && setFocusOpen(false)
            break
        }
      },
      [focus, focusOpen]
    ),
    // Observe focusIn to determine whether we have left the dropdown
    // Your ref is no good here
    focusInHandler = useCallback(
      (e: Event) => {
        const focusInDropdown =
          dropdown && containsActiveElement(dropdown as HTMLElement)
        if (focusInDropdown && !focus) setFocus(true)
        if (!focusInDropdown && focus) {
          setFocus(false)
          setFocusOpen(false)
        }
      },
      [focus, dropdown]
    )

  // Synchronize open state to hover
  useEffect(() => {
    if (hover && !open) setHoverOpen(true)
    if (!hover && open) setHoverOpen(false)
  }, [hover, open])

  // Force both types of activation on or off when state is provided
  useEffect(() => {
    if (typeof state === `undefined`) return
    if (focusOpen !== state) setFocusOpen(state)
    if (hoverOpen !== state) setHoverOpen(state)
  }, [state, focusOpen, hoverOpen])

  useEffect(() => {
    if (focusOpen && dropdownRef?.current)
      (dropdownRef.current.lastElementChild
        ?.firstElementChild as HTMLElement)?.focus()
  }, [focusOpen])

  useEventListener(`keyup`, keyUpHandler)
  useEventListener(`focusin`, focusInHandler)

  const dropdownProps = {
      tabIndex: -1,
      ref: dropdownRef,
      onMouseLeave: () => setHover(false),
      id: `${id}-container`,
    },
    toggleProps = {
      className: `relative inline-flex flex-col`,
      onMouseEnter: () => setHover(true),
      "aria-haspopup": true,
      "aria-expanded": open,
      "aria-controls": id,
    },
    menuProps = {
      className: `${
        open ? `flex pointer-events-auto` : `hidden pointer-events-none`
      } absolute top-full flex-col`,
      id,
      inert: open ? undefined : true,
    }

  const mergedProps = merge(props, dropdownProps ?? {})

  return (
    <div {...mergedProps}>
      {button && cloneElement(button, { dropdownProps: toggleProps })}
      {menu && React.cloneElement(menu, { dropdownProps: menuProps })}
    </div>
  )
}

const Button = ({
  dropdownProps,
  element,
  ...props
}: { dropdownProps?: HTMLAttributes<HTMLElement> } & {
  element?: ReactElement
} & HTMLAttributes<HTMLButtonElement>) => {
  const { className: propsClassName, ...rest } = props ?? {},
    { className: dropdownPropsClassName, ...dropdownRest } =
      dropdownProps ?? {},
    className = {
      className: `${propsClassName ? propsClassName : ``} ${
        dropdownPropsClassName ? dropdownPropsClassName : ``
      }`,
    },
    mergedProps = merge(rest, dropdownRest ?? {})
  return React.cloneElement(element || <button />, {
    ...mergedProps,
    ...className,
  })
}

const Menu = ({
  dropdownProps,
  ...props
}: { dropdownProps?: HTMLAttributes<HTMLDivElement> } & HTMLAttributes<
  HTMLDivElement
>) => {
  const { className: propsClassName, ...rest } = props ?? {},
    { className: dropdownPropsClassName, ...dropdownRest } =
      dropdownProps ?? {},
    className = {
      className: `${propsClassName ? propsClassName : ``} ${
        dropdownPropsClassName ? dropdownPropsClassName : ``
      }`,
    },
    mergedProps = merge(rest, dropdownRest ?? {})
  return <div {...mergedProps} {...className} />
}

export default { Button, Dropdown, Menu }
export { Button, Dropdown, Menu }
