import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useTranslation } from "react-i18next"
import {
  PrismicArticle,
  PrismicImageType,
  PrismicArticleBodyCarouselItemType,
  PrismicArticleBodyColumnItemType,
  PrismicArticleBodyGalleryItemType,
} from "../../graphql-types"

const SEO = ({
  data,
  languages,
  override,
}: {
  data?: PrismicArticle
  languages?: {
    lang?: string
    uid?: string
  }[]
  override?: {
    excerpt?: { text: string }
    images?: PrismicImageType[]
    title?: string
  }
}) => {
  const { t, i18n } = useTranslation(),
    {
      language,
      options: { supportedLngs },
    } = i18n,
    locale = [
      language.split(`-`)[0],
      language.split(`-`)[1].toUpperCase(),
    ].join(`-`),
    fbLocale = locale.replace(`-`, `_`),
    { pathname } = useLocation(),
    d = data?.data,
    o = override,
    siteDomain = `https://revue.fm`,
    siteTitle = `${t(`site.revue`)} ${t(`site.name.lowercase`)}`,
    siteTitleDescription = t(`site.description.short`),
    siteTitleDivider = `|`,
    defaultTitle = `${t(`site.revue`)} ${t(`site.name.lowercase`)}${
      siteTitleDescription !== ``
        ? ` ${siteTitleDivider} ${siteTitleDescription}`
        : ``
    }`,
    titleTemplate = `%s | ${t(`site.revue`)} ${t(`site.name.lowercase`)}`,
    defaultDescription = t(`site.description.long`)

  const uid = data?.uid,
    type = data?.type,
    title =
      o?.title || d?.title
        ? `${o?.title || d?.title} ${siteTitleDivider} ${siteTitle}`
        : `${siteTitle} ${
            siteTitleDescription !== ``
              ? ` ${siteTitleDivider} ${siteTitleDescription}`
              : ``
          }`

  let description = o?.excerpt?.text ?? d?.excerpt?.text ?? defaultDescription,
    images = o?.images ?? null,
    canonical = null,
    titleTags = null,
    canonicalTags = null,
    alternateTags = null,
    descriptionTags = null

  if (d?.body) {
    images = d.body?.flatMap(
      (slice: any) =>
        slice &&
        [`video`].indexOf(slice.slice_type) === -1 &&
        slice?.items
          ?.flatMap(
            (
              item:
                | PrismicArticleBodyCarouselItemType
                | PrismicArticleBodyColumnItemType
                | PrismicArticleBodyGalleryItemType
            ) => item?.image
          )
          .filter((image: any) => image?.fluid)
    )
  }

  switch (type) {
    case `article`:
      canonical = `${siteDomain}/${language}/${type}s/${uid}`
      alternateTags = languages?.map(({ lang, uid }) => (
        <link
          key={lang}
          rel="alternate"
          hrefLang={lang}
          href={`${siteDomain}/${lang}/${type}s/${uid}`}
        />
      ))
      break
    default:
      if (pathname === `/${language}`) {
        canonical = `${siteDomain}/${language}`
        alternateTags =
          supportedLngs &&
          supportedLngs
            .filter((lang) => lang !== `cimode`)
            .map((lang) => (
              <link
                key={lang}
                rel="alternate"
                hrefLang={lang}
                href={`${siteDomain}/${lang}`}
              />
            ))
      }
  }

  if (title) {
    titleTags = [
      <title key="title">{o?.title || d?.title}</title>,
      <meta key="og:title" property="og:title" content={title} />,
    ]
  }

  if (description) {
    descriptionTags = [
      <meta key="description" name="description" content={description} />,
      <meta
        key="og:description"
        property="og:description"
        content={description}
      />,
    ]
  }

  if (canonical) {
    canonicalTags = [
      // <link key="canonical" rel="canonical" href={canonical} />,
      <meta key="og:url" property="og:url" content={canonical} />,
      <meta key="og:locale" property="og:locale" content={fbLocale} />,
    ]
  }

  return (
    <Helmet defaultTitle={defaultTitle} titleTemplate={titleTemplate}>
      <html lang={locale} />

      {titleTags}
      {canonicalTags}
      {alternateTags}
      {descriptionTags}

      {images?.flatMap((image: any, i: number) => [
        <meta
          key={
            image?.fluid?.src?.replace(`https://`, `http://`) ??
            `og:image:url-${i}`
          }
          property="og:image"
          content={
            image?.fluid?.src?.replace(`https://`, `http://`)?.split(`?`)[0] +
            `?auto=compress,format&dpr=1&fit=max&q=50&w=2400`
          }
        />,
        <meta
          key={image?.fluid?.src ?? `og:image:secure_url-${i}`}
          property="og:image:secure_url"
          content={
            image?.fluid?.src?.split(`?`)[0] +
            `?auto=compress,format&dpr=1&fit=max&q=50&w=2400`
          }
        />,
        <meta
          key={image?.dimensions?.width ?? `og:image:width-${i}`}
          property="og:image:width"
          content={
            image?.dimensions?.width
              ? image?.dimensions?.width < 2400
                ? `${image?.dimensions?.width}`
                : `2400`
              : undefined
          }
        />,
        <meta
          key={image?.dimensions?.height ?? `og:image:height-${i}`}
          property="og:image:height"
          content={
            image?.dimensions?.width
              ? image?.dimensions?.width < 2400
                ? `${image?.dimensions?.height}`
                : `${Math.round(
                    (2400 * image?.dimensions?.height) /
                      image?.dimensions?.width
                  )}`
              : undefined
          }
        />,
        image?.alt ? (
          <meta key={image.alt} property="og:image:alt" content={image.alt} />
        ) : null,
      ])}
    </Helmet>
  )
}

export default SEO
export { SEO }
