import React from "react"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"
import { Monogram } from "./logos"
import { Credits } from "./credits"
import { Subscribe } from "./subscribe"

const Footer = () => {
  const { t, i18n } = useTranslation()

  return (
    <>
      <footer className="w-full bg-gray-900 text-white text-sm tracking-wide flex flex-wrap md:inline-flex-no-wrap items-start justify-start">
        <style type="text/css">{`
          :root {
            --footer-height: 119px;
          }
        `}</style>
        {/* EDPFM */}
        <div className="w-full mx-6 mt-6 mb-3 sm:w-auto sm:m-6">
          <p className="text-base tracking-normal font-medium mb-1">
            <span className="block md:hidden">{t(`brand.full`)}</span>
            <span className="hidden md:block">{t(`brand.acronym`)}</span>
          </p>
          <div>
            <a className="block" href="https://www.fredericmalle.com/">
              {t(`navigation.home`)}
            </a>
            <a
              className="block"
              href="https://www.fredericmalle.com/about/frederic-malle"
            >
              {t(`links.about`)}
            </a>
            <a className="block" href={t(`links.stores.url`)}>
              {t(`links.stores.title`)}
            </a>
          </div>
        </div>
        {/* Localization */}
        <div className="w-full mx-6 my-3 sm:w-auto sm:m-6">
          <p className="text-base tracking-normal font-medium mb-1">
            {t(`locale.country`)}
          </p>
          <div>
            <button
              className={i18n.language === `en-eu` ? `border-b` : ``}
              onClick={() => i18n.changeLanguage(`en-eu`)}
            >
              EU
            </button>
            {` `}
            <button
              className={i18n.language === `fr-fr` ? `border-b` : ``}
              onClick={() => i18n.changeLanguage(`fr-fr`)}
            >
              FR
            </button>
            {` `}
            <button
              className={i18n.language === `en-gb` ? `border-b` : ``}
              onClick={() => i18n.changeLanguage(`en-gb`)}
            >
              UK
            </button>
            {` `}
            <button
              className={i18n.language === `en-us` ? `border-b` : ``}
              onClick={() => i18n.changeLanguage(`en-us`)}
            >
              US
            </button>
          </div>
        </div>
        {/* Shop */}
        <div className="w-full mx-6 my-3 sm:w-auto sm:m-6">
          <p className="text-base tracking-normal font-medium mb-1">
            {t(`links.shop`)}
          </p>
          <div>
            <a
              className="block"
              href="https://www.fredericmalle.com/products/19566/perfume"
            >
              {t(`links.perfumes`)}
            </a>
            <a
              className="block"
              href="https://www.fredericmalle.com/body-collection"
            >
              {t(`links.body`)}
            </a>
            <a
              className="block"
              href="https://www.fredericmalle.com/home-gifts"
            >
              {t(`links.home`)}
            </a>
          </div>
        </div>
        {/* Social */}
        <div className="w-full mx-6 my-3 sm:w-auto sm:m-6">
          <p className="text-base tracking-normal font-medium mb-1">Social</p>
          <div>
            <a
              className="block"
              href="https://www.instagram.com/fredericmalle/"
            >
              Instagram
            </a>
            <a className="block" href="https://www.facebook.com/FredericMalle/">
              Facebook
            </a>
            <a className="block" href="https://www.youtube.com/fredericmalle">
              YouTube
            </a>
          </div>
        </div>
        {/* Information */}
        <div className="w-full mx-6 my-3 sm:w-auto sm:m-6">
          <p className="text-base tracking-normal font-medium mb-1">
            {t(`links.information`)}
          </p>
          <div>
            <a className="block" href="mailto:revue@fredericmalle.com">
              {t(`links.contact`)}
            </a>
            <a
              className="block"
              href="https://www.fredericmalle.com/customer-service-terms"
            >
              {t(`links.terms`)}
            </a>
            <a
              className="block"
              href="https://www.fredericmalle.com/customer-service-privacy"
            >
              {t(`links.privacy`)}
            </a>
            <a
              className="block"
              href="https://www.fredericmalle.com/accessibility"
            >
              {t(`links.accessibility`)}
            </a>
            <Credits />
          </div>
        </div>
        {/* Subscribe */}
        <div className="w-full mx-6 my-3 sm:w-auto sm:m-6">
          <p className="text-base tracking-normal font-medium mb-1">
            {t(`subscribe.title`)}
          </p>
          <div className="w-full">
            <Subscribe id="footer" />
          </div>
        </div>
        <div className="w-full mx-6 mt-3 mb-6 sm:w-auto sm:m-6 sm:ml-auto self-center">
          <Link to={`/${i18n.language}`}>
            <span className="sr-only">{t(`navigation.index`)}</span>
            <Monogram className="w-14 box-content" style={{ fill: `white` }} />
          </Link>
        </div>
      </footer>
    </>
  )
}

export default Footer
export { Footer }
