import {
  LinkResolver,
  PluginLinkResolver,
} from "gatsby-source-prismic/dist/types"

const justify = (justify: string | null | undefined) => {
  switch (justify) {
    case `Top`:
      return `justify-start`
    case `Middle`:
      return `justify-center`
    case `Bottom`:
      return `justify-end`
    default:
      return `justify-start`
  }
}

const align = (align: string | null | undefined, text?: boolean) => {
  switch (align) {
    case `Left`:
      return text ? `text-left` : `items-start`
    case `Center`:
      return text ? `text-center` : `items-center`
    case `Right`:
      return text ? `text-right` : `items-end`
    default:
      return text ? `text-left` : `items-start`
  }
}

const linkResolver: PluginLinkResolver = ({
  key,
  value,
  node,
}): LinkResolver => {
  return () => {
    switch (node.type) {
      case `splash`:
        return `/${node.lang}`
      // case `article`:
      default:
        return `/${node.lang}/${node.type}s/${node.uid}`
    }
  }
}

export { align, justify, linkResolver }
