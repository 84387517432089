import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import Img from "gatsby-image"
import { useEventListener } from "../hooks/useEventListener"
import { useArticleContext } from "../hooks/useArticleContext"
import { useTranslation } from "react-i18next"

const SiblingLink = ({ direction }: { direction?: `next` | `previous` }) => {
  const [open, setOpen] = useState(false),
    show = () => setOpen(true),
    hide = () => setOpen(false),
    [articleContext] = useArticleContext(),
    { t, i18n } = useTranslation(),
    siblingPathname =
      articleContext[
        direction === `previous` ? `previousPathname` : `nextPathname`
      ],
    siblingTitle =
      articleContext[direction === `previous` ? `previousTitle` : `nextTitle`],
    siblingDate =
      articleContext[direction === `previous` ? `previousDate` : `nextDate`],
    siblingImage =
      articleContext[direction === `previous` ? `previousImage` : `nextImage`],
    siblingImageAlt = siblingImage?.alt ?? ``,
    siblingDateObject = siblingDate && new Date(siblingDate),
    siblingDateString =
      siblingDateObject &&
      siblingDateObject.toLocaleDateString(i18n.language, {
        year:
          siblingDateObject.getFullYear() === new Date().getFullYear()
            ? undefined
            : `numeric`,
        month: `long`,
        day: `numeric`,
      }),
    siblingKey = direction === `previous` ? `ArrowRight` : `ArrowLeft`,
    keyUpHandler = (e: KeyboardEvent) => {
      if (e.key === siblingKey && siblingPathname) {
        navigate(siblingPathname)
        if (e?.target && (e.target as HTMLElement).matches(`a,button`)) {
          ;(e.target as HTMLElement).blur()
        }
      }
    }

  let siblingImageFluid = siblingImage?.fluid
  if (siblingImageFluid) siblingImageFluid.sizes = `6rem`

  useEventListener(`keyup`, keyUpHandler)

  return (
    <Link
      className={`p-3 md:p-6 ${
        direction === `previous` ? `pl-0 md:pl-0` : `pr-0 md:pr-0`
      }`}
      to={siblingPathname ?? ``}
      onMouseEnter={show}
      onMouseLeave={hide}
      onFocus={show}
      onBlur={hide}
      aria-haspopup={true}
      aria-expanded={open}
      aria-controls={`${direction}SiblingLink`}
    >
      <span
        className="font-medium"
        style={{
          fontFeatureSettings: `"tnum" 1, "onum" 1, "liga" 1, "dlig" 1`,
        }}
      >
        {direction === `previous` ? t(`navigation.next`) : t(`navigation.prev`)}
      </span>
      <div
        id={`${direction}SiblingLink`}
        className={`${
          open
            ? `opacity-100 pointer-events-auto`
            : `opacity-0 pointer-events-none`
        } absolute top-100 right-0 mx-6 w-64 flex flex-col items-end text-right text-gray-900`}
        aria-hidden={!open}
      >
        {siblingImageFluid && (
          <Img
            alt={siblingImageAlt}
            className="w-24 mt-1 mb-2"
            fluid={siblingImageFluid}
          />
        )}
        <p>
          <span className="font-normal uppercase">
            {siblingTitle ?? t(`navigation.untitled`)}
          </span>
          {` `}
          <span className="font-serif lowercase">
            {siblingDateString ?? t(`navigation.undated`)}
          </span>
        </p>
      </div>
    </Link>
  )
}

export default SiblingLink
export { SiblingLink }
