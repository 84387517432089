import React, {
  useEffect,
  useLayoutEffect,
  useState,
  HTMLAttributes,
} from "react"
import ScrollProgress from "scrollprogress"

const Progress = ({
  className,
  pathname,
  style,
  ...rest
}: HTMLAttributes<HTMLDivElement> & { pathname: string }) => {
  const [observer, setObserver] = useState<any>(),
    [progress, setProgress] = useState(0),
    handleResize = () => observer && observer._onResize()

  useEffect(() => {
    const observer = new ScrollProgress((x: number, y: number) => {
      setProgress(y * 100)
    })

    setObserver(observer)

    return () => {
      observer.destroy()
    }
  }, [])

  useEffect(handleResize)
  useLayoutEffect(handleResize)

  const width = {
    width: `${progress}%`,
  }

  return (
    <div
      className={`progress left-0 right-0 bg-red ${className}`}
      style={Object.assign({}, style, width)}
      {...rest}
    />
  )
}

export default Progress
export { Progress }
