import TimesLtPro300ItalicWoff from "./TimesLtPro-Italic.woff"
import TimesLtPro300ItalicWoff2 from "./TimesLtPro-Italic.woff2"
import TimesLtPro500ItalicWoff from "./TimesLtPro-SemiboldItalic.woff"
import TimesLtPro500ItalicWoff2 from "./TimesLtPro-SemiboldItalic.woff2"

const fonts: { [key: string]: any } = {
  TimesLtPro: {
    300: {
      italic: {
        woff: TimesLtPro300ItalicWoff,
        woff2: TimesLtPro300ItalicWoff2,
      },
    },
    500: {
      italic: {
        woff: TimesLtPro500ItalicWoff,
        woff2: TimesLtPro500ItalicWoff2,
      },
    },
  },
}

export { Fontface } from "./fontface"
export { fonts }
