import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { usePopper } from "react-popper"
import { useEventListener } from "../hooks/useEventListener"

const Credits = () => {
  const { t } = useTranslation(),
    [siteCreditActive, setSiteCreditActive] = useState(false),
    toggleSiteCreditActive = () => setSiteCreditActive(!siteCreditActive),
    [
      referenceElement,
      setReferenceElement,
    ] = useState<HTMLButtonElement | null>(null),
    [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null),
    [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null),
    { styles, attributes } = usePopper(referenceElement, popperElement, {
      placement: `top-start`,
      modifiers: [
        {
          name: `arrow`,
          options: {
            element: arrowElement,
          },
        },
        {
          name: `offset`,
          options: {
            offset: [0, 8],
          },
        },
      ],
    }),
    closeClickListener = siteCreditActive
      ? (e: Event) => {
          if (
            e.target === popperElement ||
            popperElement?.contains(e.target as Node)
          )
            return
          setSiteCreditActive(false)
        }
      : (e: Event) => {}

  useEventListener("click", closeClickListener)

  return (
    <>
      <button
        type="button"
        ref={setReferenceElement}
        aria-controls="#SiteCredit"
        aria-expanded={siteCreditActive}
        aria-haspopup="true"
        aria-label={`${siteCreditActive ? `Hide` : `Show`} site credits`}
        onClick={toggleSiteCreditActive}
      >
        {t(`credits.button`)}
      </button>

      <div
        className={`${
          siteCreditActive
            ? `opacity-100 pointer-events-auto`
            : `opacity-0 pointer-events-none`
        } transition-opacity duration-200 p-2 bg-red`}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        id="SiteCredit"
        {...{ inert: siteCreditActive ? undefined : true }}
      >
        <div>
          {t(`credits.website`)}:{" "}
          <a
            className="underline hover:text-black focus:text-black"
            href="https://studio.sl"
          >
            Spevack Loeb
          </a>
        </div>
        <div>
          {t(`credits.copywriting`)}:{" "}
          <a
            className="underline hover:text-black focus:text-black"
            href="mailto:contactthefictionist@gmail.com"
          >
            Marie Gossart
          </a>
        </div>
        <div
          className="arrow"
          ref={setArrowElement}
          style={Object.assign({}, styles.arrow, {})}
        />
      </div>
    </>
  )
}

export default Credits
export { Credits }
